import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Masonry from '../components/Masonry';
import Card from '../components/Card';
import Headline from '../components/Headline';
import Skills from '../components/Skills';
import Publications from '../components/Publications';
import Layout from '../components/Layout';
import WebGLBackground from '../components/WebGLBackground';
import SEO from '../components/SEO';
import './index.css';


export default () => {
  return (
    <Layout>
          <SEO title='Skills' />
        <Headline text="Skills" color="#5AF78E" />
        <Skills />
    </Layout>
  );
};


